require('./src/styles/global.css')
require('./src/styles/cabaret.css')
require('./src/styles/location.css')

exports.onPreRouteUpdate = ({ location }) => {
  const tposOverlay = document.getElementById('tpos-layer')
  if (tposOverlay) {
    window.tpos_dialog_close()
  }
}

exports.onClientEntry = () => {
  if (process.env.GATSBY_AUTH_REQUIRE === "true") {
    const storedPass = localStorage.getItem("gatsbyAuth");

    if (storedPass !== process.env.GATSBY_AUTH_PASSWORD) {
      const enteredPass = prompt("Entrez le mot de passe pour accéder au site:");
      if (enteredPass !== process.env.GATSBY_AUTH_PASSWORD) {
        document.body.innerHTML = "<h1>Accès refusé</h1>";
      } else {
        localStorage.setItem("gatsbyAuth", enteredPass);
      }
    }
  }
};

